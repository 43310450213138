<template>
  <div class="fraction-create">
    <div class="fraction-create-header">
      <h2>Добавить регион</h2>
    </div>
    <!-- lang tabs -->
    <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
      <a-col :span="6" style="text-align: right; padding-right: 8px">
        {{ $t("Lang") }}:
      </a-col>
      <a-col :span="12">
        <!-- lang translit tabs -->
        <a-row align="middle" class="flex-baseline flex-wrap">
          <lang-tabs v-model="langTab" class="mb-1x" />
        </a-row>
      </a-col>
    </a-row>
    <div class="fraction-create-body">
      <a-row align="middle" class="flex-baseline input-form__group">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          {{ $t("TagsColTitle") }}:
        </a-col>
        <a-col :span="12">
          <a-input
            v-model="form['title_' + getKey]"
            placeholder="Введите название"
          />
        </a-col>
      </a-row>
    </div>
    <div class="fraction-create-footer">
      <a-button
        style="margin-left: 10px"
        type="primary"
        :disabled="isFilled"
        @click="updateRegion"
      >
        <a-icon type="check" />
        {{ "Добавить" }}
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommissionCreate",
  data() {
    return {
      langTab: $langPrefix,
      form: {
        title_uz: "",
        title_en: "",
        title_qr: "",
        title_oz: "",
        title_ru: ""
      },
      allRegions: [],
      allDistrict: [],
      allSubDistricts: [],
      checkedSubditrics: null
    }
  },
  computed: {
    isFilled() {
      return (
        this.form.title_uz == "" &&
        this.form.title_oz == "" &&
        this.form.url == ""
      )
    },
    getKey() {
      return this.langTab
    },
    regionId() {
      return this.form.region
    },
    districtId() {
      return this.form.district
    }
  },
  // watch: {
  //   regionId(val) {
  //     this.getRegionDistrict(val)
  //   },
  //   districtId(val) {
  //     this.getSubDistricts(val)
  //   }
  // },
  created() {
    // this.getAllRegions()
    this.getRegionById()
  },
  methods: {
    // translitAction() {},
    // async getAllRegions() {
    //   this.allRegions = await this.$store.dispatch("okrug/fetchAllRegions")
    // },
    // async getRegionDistrict(id) {
    //   this.allDistrict = await this.$store.dispatch(
    //     "okrug/fetchRegionDistricts",
    //     id
    //   )
    // },
    // async getSubDistricts(districts) {
    //   const allSubDistricts = await this.$store.dispatch(
    //     "okrug/fetchSubDistrict",
    //     districts
    //   )
    //   this.allSubDistricts = [...allSubDistricts, ...this.checkedSubditrics]
    //   console.log("districs", this.allSubDistricts)
    // },
    async updateRegion() {
      try {
        await this.$api.patch(
          `/admin/common/region/${this.$route.params.id}/update/`,
          this.form
        )
        this.$router.go(-1)
        // console.log(this.form)
      } catch (e) {
        console.log(e)
      }
    },
    async getRegionById() {
      const form = await this.$store.dispatch(
        "region/fetchRegionById",
        this.$route.params.id
      )
      // const district = form.district.map((i) => i.id)
      // console.log("form", form)
      // this.checkedSubditrics = [
      //   {
      //     title: this.$t("selected"),
      //     id: Math.ceil(Math.random() * 10000),
      //     sub_districts: [...form.sub_district]
      //   }
      // ]
      this.form = {
        ...form
        // region: form.region.id,
      }
      // console.log(this.form)
    }
  }
}
</script>
<style>
.fraction-create {
  padding: 20px 0;
}
.form_field {
  margin: 10px 0;
  height: 70px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form_field > .fraction_image {
  width: 70px;
  height: 70px;
  border: 1px solid grey;
  border-radius: 40px;
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}
.form_field > .fraction_image > img {
  width: 100%;
  height: 100%;
}
.form_field > label {
  margin-bottom: 7px;
  display: block;
  width: 100%;
}
.form_field > div {
  width: 100%;
}
.form_field_btn {
  margin: 10px 0;
  height: 70px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.fraction-create-body {
  margin-bottom: 20px;
}
.fraction-create-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
